import React, {Component} from 'react';
import Router from '../../Router';

export default class AppBody extends Component {
  render() {
    return (
      <main className="App-body is-large">
        <Router />
      </main>
    );
  }
}
