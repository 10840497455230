import React, {Fragment, PureComponent} from 'react';
import Heading from 'react-bulma-components/lib/components/heading';
import Icon from 'react-bulma-components/lib/components/icon';
import Button from 'react-bulma-components/lib/components/button';
import afetch from '../../../../auth/afetch';
import {tournamentShape} from 'myPropTypes';
import InfoMessage from '../../../common/InfoMessage';
import PropTypes from 'prop-types';

export default class CloseRound extends PureComponent {
  static propTypes = {
    tournament: tournamentShape,
    setError: PropTypes.func.isRequired,
    setTournament: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {isLoading: false};
  }

  clickHandler(e) {
    this.setState({isLoading: true});

    afetch(`/api/tournaments/${this.props.tournament.id}/closeRound`, {
      method: 'PATCH',
    })
        .then((response) => response.json())
        .then((data) => {
          this.props.setTournament(data);
          this.setState({isLoading: false});
        });
  }

  hasUnfinishedMatches() {
    const tour = this.props.tournament;

    if (!tour || !tour.currentRound) {
      return false;
    }

    const unfinished = tour.currentRound.matches.filter((m) => !m.isFinished);

    return Boolean(unfinished.length);
  }

  renderHeader() {
    return (
      <Fragment>
        <hr className="hr" />
        <Heading size={5}>Close current round</Heading>
      </Fragment>
    );
  }

  render() {
    const tour = this.props.tournament;

    if (!tour.currentRound) {
      return null;
    }

    if (this.state.isLoading) {
      return (
        <div className="CloseRound">
          {this.renderHeader()}
          <p>
            <i className="fas fa-spinner fa-spin" />
          </p>
        </div>
      );
    }

    if (this.hasUnfinishedMatches()) {
      return (
        <div className="CloseRound">
          {this.renderHeader()}
          <InfoMessage color="warning">
            <p>
              To close the current round, add a result to every match above.
            </p>
          </InfoMessage>
        </div>
      );
    }

    return (
      <div className="CloseRound">
        {this.renderHeader()}

        <p>
          Closes the current round, round results can not be changed after this
          action.
        </p>

        <Button color="primary" onClick={this.clickHandler.bind(this)}>
          <Icon>
            <i className="fas fa-arrow-alt-circle-right" />
          </Icon>
          <span>Close current round</span>
        </Button>
      </div>
    );
  }
}
