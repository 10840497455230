import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import Navbar from 'react-bulma-components/lib/components/navbar';
import Container from 'react-bulma-components/lib/components/container';
import {pkgShape} from 'myPropTypes';
import './Header.scss';
import AppContext from '../../../contexts/AppContext';

export default class AppHeader extends Component {
  static propTypes = {
    pkg: pkgShape,
  };

  render() {
    return (
      <Navbar className="App-header" color="primary">
        <Container>
          <Navbar.Brand>
            <Navbar.Item renderAs={NavLink} to="/" exact>
              <span className="Header-logo fas fa-trophy" />
              <span className="Header-title">{this.props.pkg.name}</span>
              <span className="Header-version">v{this.props.pkg.version}</span>
            </Navbar.Item>
            {window.location.pathname.startsWith('/t/') && (
              <AppContext.Consumer>
                {({toggleSidebar}) => (
                  <Navbar.Burger onClick={toggleSidebar} />
                )}
              </AppContext.Consumer>
            )}
          </Navbar.Brand>

          <Navbar.Menu>
            <Navbar.Container position="end">
              <Navbar.Item
                renderAs={NavLink}
                activeClassName="has-text-weight-bold"
                to="/"
                exact
              >
                Home
              </Navbar.Item>
              <Navbar.Item
                renderAs={NavLink}
                activeClassName="has-text-weight-bold"
                to="/changelog"
              >
                Changelog
              </Navbar.Item>
            </Navbar.Container>
          </Navbar.Menu>
        </Container>
      </Navbar>
    );
  }
}
