import React, {Component} from 'react';
import Header from './components/Header';
import Body from './components/Body';
import Footer from './components/Footer';
import Cookies from './components/Cookies';
import AppContext from '../../contexts/AppContext';
import './App.scss';

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pkg: {},
      isSidebarOpen: false,
    };

    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  componentDidMount() {
    fetch('/api/version')
        .then((res) => res.json())
        .then((data) => this.setState({pkg: data}));
  }

  toggleSidebar() {
    this.setState((state) => ({isSidebarOpen: !state.isSidebarOpen}));
  }

  render() {
    const {isSidebarOpen} = this.state;

    return (
      <div className="App">
        <AppContext.Provider
          value={{
            isSidebarOpen,
            toggleSidebar: this.toggleSidebar,
          }}
        >
          <Cookies />
          <Header pkg={this.state.pkg} />
          <Body />
          <Footer pkg={this.state.pkg} />
        </AppContext.Provider>
      </div>
    );
  }
}
