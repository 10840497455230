import React, {Component} from 'react';
import Heading from 'react-bulma-components/lib/components/heading';
import PropTypes from 'prop-types';
import {tournamentShape} from 'myPropTypes';
import MatchList from '../common/match-list/MatchList';
import RoundHeader from './components/RoundHeader';
import './Round.scss';

export default class Round extends Component {
  static propTypes = {
    tournament: tournamentShape.isRequired,
    roundId: PropTypes.string,
  };

  getRound() {
    const {roundId, tournament} = this.props;

    if (!tournament || !tournament.pastRounds) {
      return {matches: []};
    }

    if (roundId) {
      return tournament.pastRounds.find((r) => r.id === roundId);
    } else {
      return tournament.currentRound;
    }
  }

  render() {
    const {tournament} = this.props;
    const round = this.getRound();

    if (!tournament) {
      return null;
    }

    if (!round) {
      return (
        <div>
          <Heading size={4}>No Current Round</Heading>
          <p>
            This tournament has <strong>no current round</strong>.
          </p>
          <p>
            This probably means that it has not started yet or that it has
            already finished.
          </p>
        </div>
      );
    }

    const isCurrent = Boolean(
        tournament.currentRound && round.id === tournament.currentRound.id
    );

    return (
      <div className="Round">
        <RoundHeader round={round} isCurrent={isCurrent} />
        <MatchList
          matches={round.matches}
          criteria={tournament.criteria}
          players={tournament.players}
        />
      </div>
    );
  }
}
