import React, {PureComponent} from 'react';

import {tournamentShape} from 'myPropTypes';

export default class Description extends PureComponent {
  static propTypes = {
    tournament: tournamentShape,
  };

  renderTitle() {
    return <h4 className="title is-4">Description</h4>;
  }

  render() {
    const {tournament} = this.props;

    if (!tournament) {
      return null;
    }

    return (
      <div className="Description">
        {this.renderTitle()}
        <p>{tournament.desc}</p>
      </div>
    );
  }
}
