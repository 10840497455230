import React, {Component} from 'react';

const varName = 'hasAcceptedCookies';

export default class Cookies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      [varName]: localStorage.getItem(varName),
    };
  }

  acceptHandler() {
    localStorage.setItem(varName, 'true');
    this.setState({[varName]: 'true'});
  }

  render() {
    if (this.state[varName] !== 'true') {
      return (
        <div className="Cookies-notification notification">
          <strong>Disclaimer:</strong> This site relies on <strong>cookies</strong> to work. We will not sell any of your information to 3rd parties.
          &nbsp;
          <button onClick={this.acceptHandler.bind(this)}>Accept</button>
        </div>
      );
    } else {
      return null;
    }
  }
}
