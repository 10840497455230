import React, {PureComponent, Fragment} from 'react';
import {Link} from 'react-router-dom';
import Heading from 'react-bulma-components/lib/components/heading';
import Menu from 'react-bulma-components/lib/components/menu';
import Icon from 'react-bulma-components/lib/components/icon';
import PropTypes from 'prop-types';
import getUserId from '../../../auth/getUserId';
import {tournamentShape} from 'myPropTypes';
import cx from 'classnames';
import AppContext from '../../../contexts/AppContext';

export default class TournamentMenu extends PureComponent {
  static propTypes = {
    tournament: tournamentShape.isRequired,
    location: PropTypes.object.isRequired,
  };

  renderItem({label, link, icon = 'fas fa-box'} = {}) {
    const isHere = link === this.props.location.pathname;

    return (
      <Menu.List.Item key={link}>
        <Link to={link} className={cx({'is-active': isHere})}>
          <Icon size="small">
            <i className={icon} aria-hidden="true" />
          </Icon>
          <span>{label}</span>
        </Link>
      </Menu.List.Item>
    );
  }

  renderRounds() {
    const ret = [];
    const {tournament} = this.props;
    const pastRounds = tournament.pastRounds || [];

    if (tournament.currentRound) {
      ret.push(
          this.renderItem({
            label: 'Current Round',
            link: `/t/${tournament.id}/r/current`,
            icon: 'fas fa-fist-raised',
          })
      );
    }

    for (let i = pastRounds.length - 1; i >= 0; i--) {
      ret.push(
          this.renderItem({
            label: `Round ${i + 1}`,
            link: `/t/${tournament.id}/r/${pastRounds[i].id}`,
            icon: 'fas fa-box',
          })
      );
    }

    if (!ret.length) {
      return null;
    }

    return (
      <Fragment>
        <p className="menu-label">Rounds</p>
        <Menu.List>{ret}</Menu.List>
      </Fragment>
    );
  }

  renderAdminSection() {
    const {tournament} = this.props;
    const {id, admins} = tournament;
    const uid = getUserId();

    if (!admins.includes(uid)) {
      return null;
    }

    return (
      <Fragment>
        <p className="menu-label">Admin</p>
        <ul className="menu-list">
          {this.renderItem({
            label: 'Registration',
            link: `/t/${id}/registration`,
            icon: 'fas fa-users',
          })}
          {this.renderItem({
            label: 'Manage Round',
            link: `/t/${id}/manage`,
            icon: 'fas fa-truck-loading',
          })}
        </ul>
      </Fragment>
    );
  }

  render() {
    const {tournament} = this.props;
    const {name, hasDescription, id} = tournament;

    return (
      <AppContext.Consumer>
        {({isSidebarOpen, toggleSidebar}) => (
          <Menu
            className={cx('TournamentMenu', {
              'is-active': isSidebarOpen,
            })}
            onClick={toggleSidebar}
          >
            <Heading size={4}>{name}</Heading>

            <p className="menu-label">General</p>
            <Menu.List>
              {this.renderItem({
                label: 'Ranking',
                link: `/t/${id}`,
                icon: 'fas fa-list-ol',
              })}
              {hasDescription &&
                this.renderItem({
                  label: 'Description',
                  link: `/t/${id}/description`,
                  icon: 'far fa-file-alt',
                })}
            </Menu.List>

            {this.renderRounds()}
            {this.renderAdminSection()}
          </Menu>
        )}
      </AppContext.Consumer>
    );
  }
}
