import React, {PureComponent} from 'react';
import rules from 'challenger-validation-rules';
import ValidatedField from './ValidatedField';

export default class FormBody extends PureComponent {
  render() {
    return (
      <div className="PlayerForm">
        <ValidatedField
          rule={rules.player.name}
          name="name"
          label="Name"
          icon="fas fa-user"
          inputProps={{
            autoFocus: true,
            autoComplete: 'off',
            placeholder: 'Name of the Player',
          }}
        />
      </div>
    );
  }
}
