import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {matchShape, playerShape, criteriaShape} from 'myPropTypes';
import Match from './components/Match';
import './MatchList.scss';

export default class MatchList extends PureComponent {
  static propTypes = {
    matches: PropTypes.arrayOf(matchShape),
    players: PropTypes.arrayOf(playerShape),
    criteria: PropTypes.arrayOf(criteriaShape),
  };

  render() {
    const {matches, players, criteria} = this.props;

    return (
      <div className="MatchList">
        {matches.map((match) => {
          const playerA = players.find((p) => p.id === match.playerAId);
          const playerB = players.find((p) => p.id === match.playerBId);

          return (
            <Match
              key={match.id}
              match={match}
              criteria={criteria}
              playerA={playerA}
              playerB={playerB}
            />
          );
        })}
      </div>
    );
  }
}
