import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-bulma-components/lib/components/icon';
import {tournamentShape} from 'myPropTypes';
import PlayerForm from '../../../common/forms/Player';
import afetch from '../../../../auth/afetch';
import {Form} from 'react-final-form';
import ErrorMessage from '../../../common/ErrorMessage';

export default class RegistrationForm extends PureComponent {
  static propTypes = {
    tournament: tournamentShape.isRequired,
    setTournament: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      isWorking: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit({name}, form) {
    this.setState({isWorking: true});

    const {tournament, setTournament} = this.props;

    return afetch(`/api/tournaments/${tournament.id}/players`, {
      method: 'PUT',
      body: JSON.stringify({name}),
    })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            return this.setState({error: data, isWorking: false});
          }

          setTournament(data);
          this.setState({isWorking: false});
          form.reset();
        })
        .catch((error) => this.setState(error));
  }

  render() {
    const {isWorking, error} = this.state;
    const {tournament} = this.props;

    if (!tournament) {
      return null;
    }

    return (
      <article className="RegistrationForm">
        {error.error && <ErrorMessage error={error} />}
        <Form
          onSubmit={this.onSubmit}
          render={({handleSubmit, invalid, pristine, reset}) => (
            <form onSubmit={handleSubmit}>
              <PlayerForm />
              <div className="control">
                <button
                  type="submit"
                  className="button is-primary"
                  disabled={pristine || invalid || isWorking}
                >
                  <Icon>
                    <i className="fas fa-plus-circle" />
                  </Icon>
                  <span>Add Player</span>
                </button>
              </div>
            </form>
          )}
        />
      </article>
    );
  }
}
