import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import trophy from '../../img/trophy.svg';
import Section from 'react-bulma-components/lib/components/section';
import Icon from 'react-bulma-components/lib/components/icon';
import Container from 'react-bulma-components/lib/components/container';
import Button from 'react-bulma-components/lib/components/button';
import TournamentList from '../common/tournament-list/TournamentList';
import './Home.scss';

export default class Home extends PureComponent {
  renderButton({label, to, icon, color = 'primary'} = {}) {
    return (
      <Button to={to} color={color} renderAs={Link} size="large">
        <Icon>
          <span className={icon} />
        </Icon>
        <span>{label}</span>
      </Button>
    );
  }

  render() {
    // {this.renderButton({
    //   to: '/join',
    //   label: 'Join',
    //   color: 'info',
    //   icon: 'fas fa-sign-in-alt',
    // })}

    return (
      <Section className="Home has-text-centered">
        <Container>
          <img className="Home-trophy" alt="Challenger" src={trophy} />

          <div className="Home-title">
            <h1 className="title">Challenger</h1>
            <h2 className="subtitle">
              Manage your <em>tournaments</em>.
            </h2>
          </div>

          <div className="Home-buttons is-flex">
            {this.renderButton({
              to: '/create',
              label: 'Create',
              color: 'primary',
              icon: 'fas fa-trophy',
            })}
            {this.renderButton({
              to: '/t/demo',
              label: 'Demo',
              color: 'light',
              icon: 'fas fa-eye',
            })}
          </div>
        </Container>

        <TournamentList />
      </Section>
    );
  }
}
