import React, {Component} from 'react';

import {tournamentShape} from 'myPropTypes';

export default class Rows extends Component {
  static propTypes = {
    tournament: tournamentShape,
  };

  renderRows() {
    const players = this.props.tournament.players || [];

    return players.map((player) => {
      return (
        <tr key={player.id}>
          <td>{player.currentRank}</td>
          <td>{player.name}</td>
          {this.renderTotalScores(player.totalScores)}
        </tr>
      );
    });
  }

  renderTotalScores(totalScores = []) {
    return totalScores.map((score, i) => {
      return (
        <td key={i} className="has-text-centered">
          {score}
        </td>
      );
    });
  }

  render() {
    return <tbody>{this.renderRows()}</tbody>;
  }
}
