import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import InfoMessage from './InfoMessage';

export default class Tournament extends PureComponent {
  static propTypes = {
    color: PropTypes.string,
    error: PropTypes.any.isRequired,
  };

  static defaultProps = {
    color: 'danger',
    code: '',
  };

  render() {
    const {color, error} = this.props;

    return (
      <InfoMessage color={color}>
        <strong>Error {error.code}:</strong> {error.error || error}
      </InfoMessage>
    );
  }
}
