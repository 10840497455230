import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bulma-components/lib/components/button';
import Icon from 'react-bulma-components/lib/components/icon';
import {playerShape} from 'myPropTypes';
import afetch from '../../../../../auth/afetch';

export default class PlayerListItem extends PureComponent {
  static propTypes = {
    tournamentId: PropTypes.string,
    setTournament: PropTypes.func,
    player: playerShape,
    canRemove: PropTypes.bool,
  };

  clickHandler(e) {
    e.stopPropagation();

    const {tournamentId, player} = this.props;

    afetch(`/api/tournaments/${tournamentId}/players/${player.id}`, {
      method: 'DELETE',
    })
        .then((response) => response.json())
        .then((data) => {
          this.props.setTournament(data);
        });
  }

  render() {
    return (
      <span className="PlayerListItem">
        <span>
          <Icon>
            <i className="fas fa-user" />
          </Icon>
          <strong>{this.props.player.name}</strong>
        </span>
        {this.props.canRemove && (
          <Button
            color="white"
            text={true}
            className="PlayerListItem-remove has-text-danger"
            title="remove"
            onClick={this.clickHandler.bind(this)}
          >
            <Icon>
              <i className="fas fa-trash-alt" />
            </Icon>
          </Button>
        )}
      </span>
    );
  }
}
