import React, {Component} from 'react';
import Section from 'react-bulma-components/lib/components/section';
import afetch from '../../../auth/afetch';
import TournamentListItem from './components/TournamentListItem';
import './TournamentList.scss';

export default class TournamentList extends Component {
  constructor(props) {
    super(props);
    this.state = {adminTournaments: []};
  }

  componentDidMount() {
    afetch('/api/me/tournaments')
        .then((response) => response.text())
        .then((response) => {
          const {adminTournaments} = JSON.parse(response);

          this.setState({
            adminTournaments,
          });
        });
  }

  render() {
    if (!this.state.adminTournaments.length) {
      return null;
    }

    return (
      <Section className="TournamentList has-text-centered">
        <h1 className="title is-4">My tournaments</h1>
        {this.state.adminTournaments.map((t) => (
          <TournamentListItem key={t.id} tournament={t} />
        ))}
      </Section>
    );
  }
}
