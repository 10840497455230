export default function(endpoint, options = {}) {
  options = Object.assign({
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('token'),
    },
  }, options);

  return fetch(endpoint, options);
}
