import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Section from 'react-bulma-components/lib/components/section';
import Container from 'react-bulma-components/lib/components/container';
import Icon from 'react-bulma-components/lib/components/icon';
import ErrorMessage from '../common/ErrorMessage';
import TournamentMenu from './components/TournamentMenu';
import TorunamentRouter from './components/TorunamentRouter';
import './Tournament.scss';

export default class Tournament extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  constructor(prop) {
    super(prop);

    this.state = {
      tournament: null,
      error: false,
      isWorking: false,
    };

    this.setTournament = this.setTournament.bind(this);
  }

  setTournament(tournament) {
    this.setState({tournament});
  }

  componentDidMount() {
    const {match} = this.props;
    const id = match.params.id;

    this.setState({isWorking: true});

    fetch(`/api/tournaments/${id}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            return this.setState({error: data, isWorking: false});
          }

          this.setTournament(data);
          this.setState({isWorking: false});
        })
        .catch((error) => this.setState({error}));
  }

  render() {
    const {isWorking, tournament, error} = this.state;
    const {location} = this.props;

    if (isWorking) {
      return (
        <Section className="has-text-centered">
          <Icon>
            <i className="fas fa-spinner fa-spin" />
          </Icon>
        </Section>
      );
    }

    if (!tournament) {
      return (
        <Section>
          <Container>
            <h1 className="title is-4">Tournament not found</h1>
            <p>The tournament you were looking for does no longer exist.</p>
          </Container>
        </Section>
      );
    }

    return (
      <Section>
        <Container className="Tournament">
          <TournamentMenu tournament={tournament} location={location} />
          {error ? (
            <ErrorMessage error={error} />
          ) : (
            <TorunamentRouter
              tournament={tournament}
              setTournament={this.setTournament}
            />
          )}
        </Container>
      </Section>
    );
  }
}
