import React, {PureComponent} from 'react';
import Heading from 'react-bulma-components/lib/components/heading';
import Icon from 'react-bulma-components/lib/components/icon';
import Button from 'react-bulma-components/lib/components/button';
import afetch from '../../../../auth/afetch';
import {tournamentShape} from 'myPropTypes';
import PropTypes from 'prop-types';

export default class RollbackRound extends PureComponent {
  static propTypes = {
    tournament: tournamentShape,
    setError: PropTypes.func.isRequired,
    setTournament: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {isLoading: false};
  }

  clickHandler(e) {
    this.setState({isLoading: true});

    afetch(`/api/tournaments/${this.props.tournament.id}/rollback`, {
      method: 'PATCH',
    })
        .then((response) => response.json())
        .then((data) => {
          this.props.setTournament(data);
          this.setState({isLoading: false});
        });
  }

  hasUnfinishedMatches() {
    const tour = this.props.tournament;

    if (!tour || !tour.currentRound) {
      return false;
    }

    const unfinished = tour.currentRound.matches.filter((m) => !m.isFinished);

    return Boolean(unfinished.length);
  }

  render() {
    const tour = this.props.tournament;
    const hasPreviousRounds = tour.pastRounds.length;

    if (!hasPreviousRounds) {
      return null;
    }

    if (this.state.isLoading) {
      return null;
    }

    return (
      <div className="RollbackRound">
        <hr className="hr" />
        <Heading size={5}>Rollback current round</Heading>
        <p>
          If you rollback the current round{' '}
          <strong>all results will be lost</strong> and the{' '}
          <strong>pairings will be removed</strong>.
        </p>
        <p>
          Use this action to rollback the round and pairings if you have made
          any mistakes in the previous round results.
        </p>
        <Button color="primary" onClick={this.clickHandler.bind(this)}>
          <Icon>
            <i className="fas fa-times-circle" />
          </Icon>
          <span>Rollback round</span>
        </Button>
      </div>
    );
  }
}
