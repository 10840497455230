import React, {PureComponent} from 'react';
import Table from 'react-bulma-components/lib/components/table';
import Heading from 'react-bulma-components/lib/components/heading';
import {tournamentShape} from 'myPropTypes';
import TableBody from './components/TableBody';
import TableHeader from './components/TableHeader';
import './Ranking.scss';

export default class Ranking extends PureComponent {
  static propTypes = {
    tournament: tournamentShape.isRequired,
  };

  renderTitle() {
    return <Heading size={4}>Ranking</Heading>;
  }

  render() {
    const {tournament} = this.props;

    if (!tournament.players.length) {
      return (
        <div>
          {this.renderTitle()}
          <p>There are no players registered in this tournament yet.</p>
        </div>
      );
    }

    return (
      <div className="Ranking">
        {this.renderTitle()}
        <Table>
          <thead>
            <TableHeader tournament={tournament} />
          </thead>
          <TableBody tournament={tournament} />
          <tfoot>
            <TableHeader tournament={tournament} />
          </tfoot>
        </Table>
      </div>
    );
  }
}
