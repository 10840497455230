import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Heading from 'react-bulma-components/lib/components/heading';
import AddResults from './components/AddResults';
import NextRound from './components/NextRound';
import ErrorMessage from '../../common/ErrorMessage';
import CloseRound from './components/CloseRound';
import RollbackRound from './components/RollbackRound';
import {tournamentShape} from 'myPropTypes';
import './ManageRound.scss';

export default class ManageRound extends PureComponent {
  static propTypes = {
    tournament: tournamentShape.isRequired,
    setTournament: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      error: false,
    };

    this.setError = this.setError.bind(this);
  }

  setError(error) {
    this.setState({error});
  }

  renderTitle() {
    return <Heading size={4}>Manage Round</Heading>;
  }

  renderRoundInfo() {
    const {tournament} = this.props;

    const round = tournament.currentRound
      ? tournament.currentRound.roundNumber + 1
      : 'No current round';

    return (
      <p>
        <strong>Current round:</strong> {round}
      </p>
    );
  }

  render() {
    const {tournament, setTournament} = this.props;
    const {error} = this.state;

    return (
      <div className="ManageRound">
        {this.renderTitle()}

        {error && <ErrorMessage error={error} />}

        {this.renderRoundInfo()}

        {tournament.currentRound && (
          <AddResults
            tournament={tournament}
            setTournament={setTournament}
            setError={this.setError}
          />
        )}

        <NextRound
          tournament={tournament}
          setTournament={setTournament}
          setError={this.setError}
        />

        <CloseRound
          tournament={tournament}
          setTournament={setTournament}
          setError={this.setError}
        />

        <RollbackRound
          tournament={tournament}
          setTournament={setTournament}
          setError={this.setError}
        />
      </div>
    );
  }
}
