import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Heading from 'react-bulma-components/lib/components/heading';
import {tournamentShape} from 'myPropTypes';
import PlayerList from '../common/player-list/PlayerList';
import RegistrationForm from './components/RegistrationForm';
import './Registration.scss';

export default class Registration extends PureComponent {
  static propTypes = {
    tournament: tournamentShape.isRequired,
    setTournament: PropTypes.func.isRequired,
  };

  renderTitle() {
    return <Heading size={4}>Registration</Heading>;
  }

  render() {
    const {tournament, setTournament} = this.props;
    const hasStarted = Boolean(
        tournament.currentRound || tournament.pastRounds.length
    );

    return (
      <div className="Registration">
        {this.renderTitle()}
        {hasStarted ? (
          <p>
            Players cannot be added or removed once the tournament has started.
          </p>
        ) : (
          <RegistrationForm
            tournament={tournament}
            setTournament={setTournament}
          />
        )}
        <hr />
        <PlayerList
          tournamentId={this.props.tournament.id}
          players={this.props.tournament.players}
          setTournament={this.props.setTournament}
          canRemove={!hasStarted}
        />
      </div>
    );
  }
}
