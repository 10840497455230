import React, {Fragment, PureComponent} from 'react';
import {Link} from 'react-router-dom';
import Heading from 'react-bulma-components/lib/components/heading';
import Icon from 'react-bulma-components/lib/components/icon';
import Button from 'react-bulma-components/lib/components/button';
import afetch from '../../../../auth/afetch';
import {tournamentShape} from 'myPropTypes';
import InfoMessage from '../../../common/InfoMessage';

export default class NextRound extends PureComponent {
  static propTypes = {
    tournament: tournamentShape,
  };

  constructor(props) {
    super(props);

    this.state = {isLoading: false};
  }

  clickHandler(e) {
    this.setState({isLoading: true});

    afetch(`/api/tournaments/${this.props.tournament.id}/nextRound`, {
      method: 'PATCH',
    })
        .then((response) => response.json())
        .then((data) => {
          window.location = `/t/${data.id}/r/current`;
        });
  }

  getUnfinishedMatches() {
    const tour = this.props.tournament;

    if (!tour || !tour.currentRound) {
      return false;
    }

    return tour.currentRound.matches.filter((m) => !m.isFinished);
  }

  renderHeader() {
    const tour = this.props.tournament;
    const hasRounds = tour.currentRound || tour.pastRounds.length;

    return (
      <Fragment>
        <hr className="hr" />
        <Heading size={5}>Start {hasRounds ? 'next' : 'first'} round</Heading>
      </Fragment>
    );
  }

  render() {
    const unfinished = this.getUnfinishedMatches();
    const tour = this.props.tournament;

    if (this.state.isLoading) {
      return (
        <div className="NextRound">
          {this.renderHeader()}
          <p>
            <i className="fas fa-spinner fa-spin" />
          </p>
        </div>
      );
    }

    if (tour.players && !tour.players.length) {
      return (
        <div className="NextRound">
          {this.renderHeader()}

          <InfoMessage color="warning">
            <p>Cannot start a new round. There are no players registered.</p>
            <p>
              <Icon>
                <i className="fas fa-user-plus" />
              </Icon>
              <Link to={`/t/${tour.id}/registration`}>
                <span>Add players</span>
              </Link>
            </p>
          </InfoMessage>
        </div>
      );
    }

    if (unfinished && unfinished.length) {
      return (
        <div className="NextRound">
          {this.renderHeader()}
          <InfoMessage color="warning">
            <p>To start a new round, add a result to every match above.</p>
          </InfoMessage>
        </div>
      );
    }

    return (
      <div className="NextRound">
        {this.renderHeader()}

        <p>
          Starting a new round will roll new matches for every player based on
          previous results (Swiss System).
        </p>

        <Button color="primary" onClick={this.clickHandler.bind(this)}>
          <Icon>
            <i className="fas fa-arrow-alt-circle-right" />
          </Icon>
          <span>
            {tour.currentRound && 'Close current round + '}Start new round
          </span>
        </Button>
      </div>
    );
  }
}
