import React, {Fragment, PureComponent} from 'react';
import Heading from 'react-bulma-components/lib/components/heading';
import Icon from 'react-bulma-components/lib/components/icon';
import {Link} from 'react-router-dom';
import {tournamentShape} from 'myPropTypes';
import PropTypes from 'prop-types';
import HeaderRow from './HeaderRow';
import Row from './Row';
import InfoMessage from '../../../common/InfoMessage';

export default class AddResults extends PureComponent {
  static propTypes = {
    tournament: tournamentShape.isRequired,
    setTournament: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
  };

  renderTitle() {
    return (
      <Fragment>
        <hr className="hr" />
        <Heading size={5}>Round Results</Heading>
      </Fragment>
    );
  }

  render() {
    const {tournament, setTournament, setError} = this.props;

    if (!tournament.currentRound) {
      return (
        <div className="AddResults">
          {this.renderTitle()}

          <InfoMessage color="danger">
            <p>
              <strong>No round</strong> is being played.
            </p>
            <p>Start a new round before adding results.</p>
          </InfoMessage>

          <p>
            <Link to={`/t/${tournament.id}/next`}>
              <Icon>
                <i className="fas fa-truck-loading" />
              </Icon>
              <span>Start a new round</span>
            </Link>
          </p>
        </div>
      );
    }

    return (
      <div className="AddResults">
        {this.renderTitle()}

        <table className="table">
          <thead>
            <HeaderRow promptableScores={tournament.promptableScores} />
          </thead>
          <tbody>
            {tournament.currentRound.matches.map((match) => (
              <Row
                key={match.id}
                match={match}
                tournament={tournament}
                setError={setError}
                setTournament={setTournament}
              />
            ))}
          </tbody>
          <tfoot>
            <HeaderRow promptableScores={tournament.promptableScores} />
          </tfoot>
        </table>
      </div>
    );
  }
}
