import PropTypes from 'prop-types';

export const children = PropTypes.oneOf([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);

export const pkgShape = PropTypes.shape({
  name: PropTypes.string,
  version: PropTypes.string,
  author: PropTypes.string,
  repository: PropTypes.string,
});

export const criteriaShape = PropTypes.shape({
  label: PropTypes.string,
  desc: PropTypes.string,
  type: PropTypes.string,
  criteria: PropTypes.string,
});

export const matchShape = PropTypes.shape({
  id: PropTypes.string,
  playerAid: PropTypes.string,
  playerBid: PropTypes.string,
  scoreA: PropTypes.object,
  scoreB: PropTypes.object,
  isFinished: PropTypes.bool,
});

export const roundShape = PropTypes.shape({
  id: PropTypes.string,
  matches: PropTypes.arrayOf(matchShape),
});

export const playerShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

export const tournamentShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  desc: PropTypes.string,
  criteria: PropTypes.arrayOf(criteriaShape),
  players: PropTypes.arrayOf(playerShape),
  pastRounds: PropTypes.arrayOf(roundShape),
  currentRound: roundShape,
});
