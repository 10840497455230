import getUserId from './getUserId';

export default function() {
  const uid = getUserId();

  return fetch('/api/token', {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({uid}),
  })
      .then((response) => response.text())
      .then((response) => {
        const {uid, token} = JSON.parse(response);

        localStorage.setItem('uid', uid);
        localStorage.setItem('token', token);
      });
}
