import React, {PureComponent} from 'react';
import {Form} from 'react-final-form';
import createFocusDecorator from 'final-form-focus';
import TournamentForm from '../common/forms/Tournament';
import afetch from '../../auth/afetch';
import {Redirect} from 'react-router-dom';
import Section from 'react-bulma-components/lib/components/section';
import Button from 'react-bulma-components/lib/components/button';
import ErrorMessage from '../common/ErrorMessage';
import './CreateTournament.scss';

const focusOnErrors = createFocusDecorator();

export default class CreateTournament extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      redirectTo: false,
      error: false,
      working: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit({name, desc}) {
    this.setState({working: true});

    afetch('/api/tournaments', {
      method: 'PUT',
      body: JSON.stringify({name, desc}),
    })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            this.setState({
              error: data,
              working: false,
            });

            return;
          }

          this.setState({
            redirectTo: `/t/${data.id}`,
            working: false,
          });
        })
        .catch((error) => this.setState(error));
  }

  render() {
    const {error, redirectTo, working} = this.state;

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    return (
      <Section className="Create has-text-centered">
        <h1 className="title">Create your tournament</h1>

        <div className="Create-form">
          {error && <ErrorMessage error={error} />}
          <Form
            onSubmit={this.onSubmit}
            decorators={[focusOnErrors]}
            render={({handleSubmit, invalid, pristine}) => (
              <form onSubmit={handleSubmit}>
                <TournamentForm />
                <div className="field">
                  <strong>
                    <i className="fas fa-unlock-alt" /> Not Secure:
                  </strong>{' '}
                  Tournaments created right now can only be edited through the
                  device that created them. We expect to provide a more secure
                  system in the near future, using 3rd party authentication like
                  Google.
                </div>
                <div className="control has-text-centered">
                  <Button
                    color="primary"
                    type="submit"
                    disabled={pristine || invalid || working}
                  >
                    Create
                  </Button>
                </div>
              </form>
            )}
          />
        </div>
      </Section>
    );
  }
}
