import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Message from 'react-bulma-components/lib/components/message';

export default class Tournament extends PureComponent {
  static propTypes = {
    color: PropTypes.string,
    children: PropTypes.any,
  };

  static defaultProps = {
    color: 'info',
  };

  render() {
    const {color, children} = this.props;

    return (
      <Message color={color}>
        <Message.Body>{children}</Message.Body>
      </Message>
    );
  }
}
