import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import PlayerPoints from './PlayerPoints';
import PlayerTag from './PlayerTag';
import {matchShape, playerShape, criteriaShape} from 'myPropTypes';
import crossedSwordsImg from '../../../../../img/crossed-swords.svg';

export default class Match extends PureComponent {
  static propTypes = {
    match: matchShape,
    playerA: playerShape,
    playerB: playerShape,
    criteria: PropTypes.arrayOf(criteriaShape),
  };

  renderPlayerResult(player, score) {
    return (
      <div>
        <PlayerTag playerId={player.id} match={this.props.match} />
        <div>
          <strong className="is-size-5">{player.name}</strong>
        </div>
        <div>
          <PlayerPoints score={score} criteria={this.props.criteria} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Match">
        {
          // <div className="Match-table">
          //   Table: 1
          // </div>
        }
        <div className="Match-player">
          {this.renderPlayerResult(this.props.playerA, this.props.match.scoreA)}
        </div>
        <div className="Match-versus">
          <hr />
          <figure className="container image is-32x32">
            <img src={crossedSwordsImg} alt="VS" />
          </figure>
          <hr />
        </div>
        <div className="Match-player">
          {this.renderPlayerResult(this.props.playerB, this.props.match.scoreB)}
        </div>
      </div>
    );
  }
}
