import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';

import Home from './home/Home';
import Changelog from './changelog/Changelog';
import Tournament from './tournament/Tournament';
import CreateTournament from './create-tournament/CreateTournament';

export default class Router extends Component {
  render() {
    return (
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/changelog" component={Changelog} />
        <Route path="/create" component={CreateTournament} />
        <Route path="/t/:id" component={Tournament} />
      </Switch>
    );
  }
}
