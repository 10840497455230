import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {criteriaShape} from 'myPropTypes';

export default class PlayerPoints extends PureComponent {
  static propTypes = {
    criteria: PropTypes.arrayOf(criteriaShape),
    score: PropTypes.object,
  };

  render() {
    const {score, criteria} = this.props;
    const ret = [];

    for (const crit of criteria) {
      let points = score && score[crit.type];

      if (isNaN(points)) {
        points = '?';
      }

      if (!crit.criteria.startsWith('opponents')) {
        ret.push(
            <span key={crit.type} className="PlayerPoints">
              <span className="PlayerPoints-key">
                <abbr title={crit.desc}>{crit.label}</abbr>:
              </span>
              <span className="PlayerPoints-value">{points}</span>
            </span>
        );
      }
    }

    return ret;
  }
}
