import React, {PureComponent} from 'react';

export default class Changelog extends PureComponent {
  render() {
    return (
      <section className="section">
        <div className="container">
          <h1 className="title">Changelog</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipiscing elit curae, dis
            ultrices mollis at nostra velit auctor rutrum, viverra ornare
            facilisi venenatis tempus condimentum magnis. Turpis laoreet
            suspendisse vehicula quisque duis primis ad fames lectus ut, feugiat
            aptent tincidunt lacinia morbi ante felis curae suscipit lobortis,
            sem porta quis molestie potenti urna sed per parturient.
          </p>
          <p>
            Aenean id massa facilisi turpis feugiat facilisis nostra aliquet
            velit libero quisque, mus ullamcorper rutrum neque vestibulum augue
            conubia bibendum metus a litora nisi, praesent donec fermentum
            aliquam leo iaculis nulla justo mollis elementum. Dis sapien
            parturient egestas dictum porttitor tempus vestibulum sociosqu
            dapibus suspendisse, nascetur diam taciti venenatis fringilla tempor
            vehicula cras curabitur. Pharetra eu ante leo duis consequat
            phasellus ultrices ullamcorper, gravida potenti volutpat enim per
            conubia aliquet, augue dis quisque facilisi eleifend fermentum
            sociis. Facilisi potenti eu lobortis habitant venenatis egestas,
            platea metus porttitor sagittis at varius, vivamus dictumst tellus
            condimentum cras.
          </p>
          <p>
            Volutpat vel nisl tellus sapien semper velit sagittis ligula risus,
            proin class condimentum litora interdum ultrices scelerisque. Mauris
            suspendisse conubia torquent dis felis gravida sed dapibus,
            malesuada odio posuere potenti porttitor sem cursus pulvinar
            aliquet, condimentum sociis vivamus bibendum nullam risus sagittis.
            Ad aptent metus vel sociis mollis ligula donec orci dis, venenatis
            montes feugiat dictum pharetra elementum ut pretium torquent,
            ultricies tempor nascetur imperdiet eu vitae eros hac. Convallis
            montes massa himenaeos elementum dignissim, nibh vivamus metus eu
            nec, sem est phasellus imperdiet.
          </p>
          <p>
            Vestibulum habitant cum varius non ante maecenas dictumst, iaculis a
            parturient eu ridiculus platea, dictum imperdiet augue lacus neque
            integer. Congue sed libero himenaeos et tristique diam ornare
            suspendisse purus nascetur integer, natoque cum semper dictumst
            habitasse facilisi feugiat inceptos rhoncus penatibus. Luctus velit
            ultrices class fringilla sed imperdiet viverra nisl, sociis urna
            nisi arcu eros litora vitae ac, et faucibus fames aliquet congue
            libero inceptos.
          </p>
          <p>
            Vulputate quisque laoreet felis facilisi fusce rhoncus semper
            volutpat orci, quis egestas dictum habitasse ornare et massa purus
            netus, nisl rutrum varius erat aliquam cursus gravida maecenas.
            Congue litora tristique non duis facilisis justo morbi suscipit
            diam, porta feugiat donec dapibus nisi nunc mus netus lobortis,
            ultricies conubia sociis sodales erat semper rhoncus accumsan. Porta
            commodo posuere accumsan vehicula non dui bibendum inceptos, rhoncus
            eros aliquam primis a quis ut suscipit consequat, conubia himenaeos
            nascetur magnis etiam ornare leo. Feugiat rhoncus platea venenatis
            hac quis commodo vestibulum risus sodales, porta neque aliquet
            ornare lacinia imperdiet ligula nostra, varius nisi per posuere
            tellus himenaeos condimentum fermentum.
          </p>
          <p>
            Tellus himenaeos laoreet lacinia vulputate fusce penatibus aliquet
            donec auctor, dui congue duis erat cursus litora ligula suspendisse
            dapibus, magnis nam potenti cras tortor fermentum condimentum
            primis. Odio neque luctus euismod felis cras viverra dui diam
            interdum, vel orci iaculis auctor ut ridiculus penatibus himenaeos.
            Arcu nunc ante quis vitae lacus nulla magnis volutpat velit tempus
            tortor varius tellus turpis eget erat lacinia, class habitant
            sodales himenaeos sociis lectus tincidunt aliquam suscipit primis
            hac pretium auctor fringilla blandit pharetra.
          </p>
          <p>
            Fringilla hac vulputate ultrices rutrum proin aenean, facilisis
            aliquam blandit eget varius metus, faucibus malesuada ullamcorper
            cubilia dictumst. Sagittis phasellus augue tristique placerat duis
            sem sollicitudin cras metus, dis commodo purus curabitur laoreet
            magnis orci vivamus, donec odio felis hendrerit suscipit turpis leo
            posuere. Phasellus mi penatibus mauris auctor ut proin netus,
            sodales cum iaculis urna quis porta, accumsan nullam orci ante nisi
            pharetra.
          </p>
          <h1 className="title">Changelog</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipiscing elit curae, dis
            ultrices mollis at nostra velit auctor rutrum, viverra ornare
            facilisi venenatis tempus condimentum magnis. Turpis laoreet
            suspendisse vehicula quisque duis primis ad fames lectus ut, feugiat
            aptent tincidunt lacinia morbi ante felis curae suscipit lobortis,
            sem porta quis molestie potenti urna sed per parturient.
          </p>
          <p>
            Aenean id massa facilisi turpis feugiat facilisis nostra aliquet
            velit libero quisque, mus ullamcorper rutrum neque vestibulum augue
            conubia bibendum metus a litora nisi, praesent donec fermentum
            aliquam leo iaculis nulla justo mollis elementum. Dis sapien
            parturient egestas dictum porttitor tempus vestibulum sociosqu
            dapibus suspendisse, nascetur diam taciti venenatis fringilla tempor
            vehicula cras curabitur. Pharetra eu ante leo duis consequat
            phasellus ultrices ullamcorper, gravida potenti volutpat enim per
            conubia aliquet, augue dis quisque facilisi eleifend fermentum
            sociis. Facilisi potenti eu lobortis habitant venenatis egestas,
            platea metus porttitor sagittis at varius, vivamus dictumst tellus
            condimentum cras.
          </p>
          <p>
            Volutpat vel nisl tellus sapien semper velit sagittis ligula risus,
            proin class condimentum litora interdum ultrices scelerisque. Mauris
            suspendisse conubia torquent dis felis gravida sed dapibus,
            malesuada odio posuere potenti porttitor sem cursus pulvinar
            aliquet, condimentum sociis vivamus bibendum nullam risus sagittis.
            Ad aptent metus vel sociis mollis ligula donec orci dis, venenatis
            montes feugiat dictum pharetra elementum ut pretium torquent,
            ultricies tempor nascetur imperdiet eu vitae eros hac. Convallis
            montes massa himenaeos elementum dignissim, nibh vivamus metus eu
            nec, sem est phasellus imperdiet.
          </p>
          <p>
            Vestibulum habitant cum varius non ante maecenas dictumst, iaculis a
            parturient eu ridiculus platea, dictum imperdiet augue lacus neque
            integer. Congue sed libero himenaeos et tristique diam ornare
            suspendisse purus nascetur integer, natoque cum semper dictumst
            habitasse facilisi feugiat inceptos rhoncus penatibus. Luctus velit
            ultrices class fringilla sed imperdiet viverra nisl, sociis urna
            nisi arcu eros litora vitae ac, et faucibus fames aliquet congue
            libero inceptos.
          </p>
          <p>
            Vulputate quisque laoreet felis facilisi fusce rhoncus semper
            volutpat orci, quis egestas dictum habitasse ornare et massa purus
            netus, nisl rutrum varius erat aliquam cursus gravida maecenas.
            Congue litora tristique non duis facilisis justo morbi suscipit
            diam, porta feugiat donec dapibus nisi nunc mus netus lobortis,
            ultricies conubia sociis sodales erat semper rhoncus accumsan. Porta
            commodo posuere accumsan vehicula non dui bibendum inceptos, rhoncus
            eros aliquam primis a quis ut suscipit consequat, conubia himenaeos
            nascetur magnis etiam ornare leo. Feugiat rhoncus platea venenatis
            hac quis commodo vestibulum risus sodales, porta neque aliquet
            ornare lacinia imperdiet ligula nostra, varius nisi per posuere
            tellus himenaeos condimentum fermentum.
          </p>
          <p>
            Tellus himenaeos laoreet lacinia vulputate fusce penatibus aliquet
            donec auctor, dui congue duis erat cursus litora ligula suspendisse
            dapibus, magnis nam potenti cras tortor fermentum condimentum
            primis. Odio neque luctus euismod felis cras viverra dui diam
            interdum, vel orci iaculis auctor ut ridiculus penatibus himenaeos.
            Arcu nunc ante quis vitae lacus nulla magnis volutpat velit tempus
            tortor varius tellus turpis eget erat lacinia, class habitant
            sodales himenaeos sociis lectus tincidunt aliquam suscipit primis
            hac pretium auctor fringilla blandit pharetra.
          </p>
          <p>
            Fringilla hac vulputate ultrices rutrum proin aenean, facilisis
            aliquam blandit eget varius metus, faucibus malesuada ullamcorper
            cubilia dictumst. Sagittis phasellus augue tristique placerat duis
            sem sollicitudin cras metus, dis commodo purus curabitur laoreet
            magnis orci vivamus, donec odio felis hendrerit suscipit turpis leo
            posuere. Phasellus mi penatibus mauris auctor ut proin netus,
            sodales cum iaculis urna quis porta, accumsan nullam orci ante nisi
            pharetra.
          </p>
        </div>
      </section>
    );
  }
}
