import React, {Component} from 'react';

import {tournamentShape} from 'myPropTypes';

export default class Row extends Component {
  static propTypes = {
    tournament: tournamentShape,
  };

  renderColumns() {
    const criteria = this.props.tournament.criteria || [];

    return criteria.map((crit, i) => {
      return (
        <th key={i} className="has-text-centered">
          <abbr title={crit.desc}>{crit.label}</abbr>
        </th>
      );
    });
  }

  render() {
    return (
      <tr>
        <th>
          <abbr title="position">Pos</abbr>
        </th>
        <th>Player</th>
        {this.renderColumns()}
      </tr>
    );
  }
}
