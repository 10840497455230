import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {matchShape} from 'myPropTypes';

const resultTypes = {
  UNKNOWN: -1,
  PLAYER_A_WINS: 1,
  PLAYER_B_WINS: 2,
  DRAW: 0,
};

export default class PlayerTag extends PureComponent {
  static propTypes = {
    match: matchShape,
    playerId: PropTypes.string,
  };

  render() {
    const {match, playerId} = this.props;

    if (!match.isFinished) {
      return <UnknownTag />;
    }

    switch (match.result) {
      case resultTypes.DRAW:
        return <DrawTag />;
      case resultTypes.PLAYER_A_WINS:
        return playerId === match.playerAId ? <WinnerTag /> : <LoserTag />;
      case resultTypes.PLAYER_B_WINS:
        return playerId === match.playerBId ? <WinnerTag /> : <LoserTag />;
      default:
      case resultTypes.UNKNOWN:
        return <UnknownTag />;
    }
  }
}

export function WinnerTag() {
  return (
    <div>
      <strong className="has-text-success">Winner!</strong>
    </div>
  );
}

export function LoserTag() {
  return (
    <div>
      <strong className="has-text-danger">Loser</strong>
    </div>
  );
}

export function DrawTag() {
  return (
    <div>
      <strong className="has-text-info">Draw</strong>
    </div>
  );
}

export function UnknownTag() {
  return (
    <div>
      <strong className="has-text-grey-light">In Progress</strong>
    </div>
  );
}
