import React, {Component} from 'react';
import Icon from 'react-bulma-components/lib/components/icon';
import {Link} from 'react-router-dom';
import {tournamentShape} from 'myPropTypes';

export default class TournamentListItem extends Component {
  static propTypes = {
    tournament: tournamentShape,
  };

  render() {
    return (
      <div className="TournamentListItem">
        <span className="TournamentListItem-name">
          {this.props.tournament.name}
          <span className="TournamentListItem-role">as admin</span>
        </span>

        <span className="TournamentListItem-link">
          <Link to={`/t/${this.props.tournament.id}`}>
            <Icon className="fas fa-chevron-circle-right" />
          </Link>
        </span>
      </div>
    );
  }
}
