var yup = require('yup');

var createValidator = require('./create-validator');

var createValidatorFromPromptableScores = require('./create-validator-from-promptable-scores');

exports.player = createValidator({
  name: yup.string().max(100).min(3).required()
});
exports.tournament = createValidator({
  name: yup.string().max(100).min(5).required(),
  description: yup.string().max(1024)
});

exports.getResultsValidator = function (promptableCriteria) {
  return createValidator({
    scoresA: createValidatorFromPromptableScores(promptableCriteria),
    scoresB: createValidatorFromPromptableScores(promptableCriteria)
  });
};

exports.validateForm = function (schema) {
  return function (value) {
    try {
      schema.validateSync(value);
      return undefined;
    } catch (err) {
      return err.errors;
    }
  };
};