import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {criteriaShape} from 'myPropTypes';

export default class HeaderRow extends PureComponent {
  static propTypes = {
    promptableScores: PropTypes.arrayOf(criteriaShape).isRequired,
  };

  renderColumns() {
    const promptableScores = this.props.promptableScores || [];

    return promptableScores.map((crit, i) => {
      return (
        <th key={i} className="has-text-centered">
          <abbr title={crit.desc}>{crit.label}</abbr>
        </th>
      );
    });
  }

  render() {
    return (
      <tr>
        <th>Player A</th>
        {this.renderColumns()}

        <th className="AddResults-divider" />

        <th>Player B</th>
        {this.renderColumns()}

        <th className="AddResults-divider" />

        <th />
      </tr>
    );
  }
}
