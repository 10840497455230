import React from 'react';
import ReactDOM from 'react-dom';
import App from './views/app/App';
import './_styles.scss';
import login from './auth/login';
import {BrowserRouter} from 'react-router-dom';

login().then(() => {
  ReactDOM.render(
      <BrowserRouter>
        <App />
      </BrowserRouter>,
      document.getElementById('root')
  );
});
