import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bulma-components/lib/components/button';
import {matchShape, tournamentShape} from 'myPropTypes';
import crossedSwordsImg from '../../../../img/crossed-swords.svg';
import afetch from '../../../../auth/afetch';

export default class Row extends PureComponent {
  static propTypes = {
    match: matchShape.isRequired,
    tournament: tournamentShape.isRequired,
    setError: PropTypes.func.isRequired,
    setTournament: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const {tournament, match} = props;
    const {players} = tournament;

    this.players = {
      playerA: players.find((p) => p.id === match.playerAId),
      playerB: players.find((p) => p.id === match.playerBId),
    };

    this.state = {
      [this.players.playerA.id]: match.scoreA,
      [this.players.playerB.id]: match.scoreB,
      isWorking: false,
    };

    this.changeHandler = this.changeHandler.bind(this);
  }

  save() {
    const {tournament, match, setTournament, setError} = this.props;
    const {playerA, playerB} = this.players;
    const data = {
      scoresA: this.state[playerA.id],
      scoresB: this.state[playerB.id],
    };

    this.setState({isWorking: true});

    afetch(`/api/tournaments/${tournament.id}/matches/${match.id}/results`, {
      method: 'PATCH',
      body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .then((data) => {
          this.setState({isWorking: false});

          return data.error ? setError(data) : setTournament(data);
        })
        .catch((error) => setError(error));
  }

  changeHandler(e) {
    const {name, value} = e.target;
    const [player, scoreType] = name.split('/');

    this.setState({
      [player]: {
        [scoreType]: value,
      },
    });
  }

  renderCells(playerOrdinal) {
    const {match, tournament} = this.props;
    const {players, promptableScores} = tournament;

    const player = players.find(
        (p) => p.id === match[`player${playerOrdinal}Id`]
    );

    return promptableScores.map((crit, i) => {
      const current = match[`score${playerOrdinal}`][crit.type];

      return (
        <td key={i} className="has-text-centered">
          <input
            type="number"
            autoComplete="off"
            name={`${player.id}/${crit.type}`}
            defaultValue={current}
            onChange={this.changeHandler}
          />
        </td>
      );
    });
  }

  render() {
    const {match} = this.props;
    const {isWorking} = this.state;
    const {playerA, playerB} = this.players;

    return (
      <tr>
        <td>
          <strong>{playerA.name}</strong>
        </td>

        {this.renderCells('A')}

        <td className="AddResultsRow-divider">
          <figure className="container image is-16x16">
            <img src={crossedSwordsImg} alt="VS" />
          </figure>
        </td>

        <td>
          <strong>{playerB.name}</strong>
        </td>

        {this.renderCells('B')}

        <td className="AddResultsRow-divider" />

        <td className="AddResultsRow-button">
          <Button
            className="is-16x16"
            text={true}
            onClick={() => this.save(match.id)}
            disabled={isWorking}
          >
            <i className="fas fa-save" />
          </Button>
        </td>
      </tr>
    );
  }
}
