import React, {PureComponent, Fragment} from 'react';
import {Field} from 'react-final-form';
import rules from 'challenger-validation-rules';
import PropTypes from 'prop-types';

export default class ValidatedField extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
    rule: PropTypes.object,
    inputProps: PropTypes.object,
  };

  render() {
    const {rule, name, label, icon, inputProps} = this.props;

    return (
      <Fragment>
        <label className="label">{label}</label>
        <Field
          name={name}
          validate={rules.validateForm(rule)}
          render={({input, meta}) => {
            const showError =
              meta.error && meta.touched && !meta.pristine && meta.active;

            return (
              <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    {...input}
                    {...inputProps}
                    className={showError ? 'input is-danger' : 'input'}
                  />

                  {icon && (
                    <span className="icon is-small is-left">
                      <i className={icon} />
                    </span>
                  )}
                </p>
                {showError && <p className="help is-danger">{meta.error}</p>}
              </div>
            );
          }}
        />
      </Fragment>
    );
  }
}
