import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import Description from '../description/Description';
import Ranking from '../ranking/Ranking';
import Round from '../round/Round';
import Registrstion from '../registration/Registration';
import {tournamentShape} from 'myPropTypes';
import ManageRound from '../manage-round/ManageRound';

export default class TournamentBody extends Component {
  static propTypes = {
    tournament: tournamentShape.isRequired,
    setTournament: PropTypes.func.isRequired,
  };

  render() {
    const {tournament, setTournament} = this.props;

    return (
      <div className="TournamentBody">
        <Switch>
          <Route
            path="/t/:id"
            exact
            render={() => <Ranking tournament={tournament} />}
          />
          <Route
            path="/t/:id/description"
            render={() => <Description tournament={tournament} />}
          />
          <Route
            path="/t/:id/r/current"
            render={() => <Round tournament={tournament} />}
          />
          <Route
            path="/t/:id/r/:roundId"
            render={({match}) => (
              <Round roundId={match.params.roundId} tournament={tournament} />
            )}
          />
          <Route
            path="/t/:id/registration"
            render={({match}) => (
              <Registrstion
                tournament={tournament}
                setTournament={setTournament}
              />
            )}
          />
          <Route
            path="/t/:id/manage"
            render={() => (
              <ManageRound
                tournament={tournament}
                setTournament={setTournament}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}
