import React, {PureComponent} from 'react';
import {Field} from 'react-final-form';
import rules from 'challenger-validation-rules';

export default class FormBody extends PureComponent {
  render() {
    return (
      <div className="TournamentForm">
        <label className="label">Name</label>
        <Field
          name="name"
          validate={rules.validateForm(rules.tournament.name)}
          render={({input, meta}) => (
            <div className="field">
              <p className="control is-expanded has-icons-left">
                <input
                  {...input}
                  autoFocus
                  component="input"
                  autoComplete="off"
                  maxLength="100"
                  className={
                    meta.touched && meta.error ? 'input is-danger' : 'input'
                  }
                  type="text"
                  placeholder="Name of the tournament"
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-trophy" />
                </span>
              </p>
              {meta.touched && meta.error && (
                <p className="help is-danger">{meta.error}</p>
              )}
            </div>
          )}
        />

        <label className="label">Description</label>
        <div className="field">
          <div className="control">
            <Field
              name="desc"
              component="textarea"
              className="textarea"
              maxLength="1024"
              placeholder="A short description of your tournament"
            />
          </div>
          <p className="help">Max 1024 characters.</p>
        </div>
      </div>
    );
  }
}
