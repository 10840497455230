import React, {Component} from 'react';
import Icon from 'react-bulma-components/lib/components/icon';
import Heading from 'react-bulma-components/lib/components/heading';
import Progress from 'react-bulma-components/lib/components/progress';
import PropTypes from 'prop-types';
import {roundShape} from 'myPropTypes';

export default class RoundHeader extends Component {
  static propTypes = {
    round: roundShape.isRequired,
    isCurrent: PropTypes.bool.isRequired,
  };

  getFinishedMatches() {
    if (this.props.round) {
      return this.props.round.matches.filter((m) => m.isFinished).length;
    }
  }

  getTotalMatches() {
    if (this.props.round) {
      return this.props.round.matches.length;
    }
  }

  render() {
    const {round, isCurrent} = this.props;
    const totalMatches = this.getTotalMatches();
    const finishedMatches = this.getFinishedMatches();
    const isFinished = totalMatches === finishedMatches;

    if (!this.props.round) {
      return null;
    }

    return (
      <div className="RoundHeader">
        <Heading size={4}>
          Round {round.roundNumber + 1} {isCurrent && <span>(Current)</span>}
        </Heading>
        <div className="RoundHeader-progress">
          {isFinished ? (
            <p className="has-text-success">
              <Icon>
                <i className="fas fa-check-circle" />
              </Icon>
              <span>Done!</span>
            </p>
          ) : (
            <Progress value={finishedMatches} max={totalMatches} />
          )}
        </div>
        <div className="RoundHeader-matchesFinished">
          <strong>Matches finished:</strong> {finishedMatches} of {totalMatches}
        </div>
      </div>
    );
  }
}
