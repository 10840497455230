import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Heading from 'react-bulma-components/lib/components/heading';
import {playerShape} from 'myPropTypes';
import PlayerCard from './components/PlayerListItem';
import './PlayerList.scss';

export default class PlayerCardList extends PureComponent {
  static propTypes = {
    tournamentId: PropTypes.string.isRequired,
    setTournament: PropTypes.func.isRequired,
    players: PropTypes.arrayOf(playerShape).isRequired,
    canRemove: PropTypes.bool.isRequired,
  };

  renderTitle() {
    return <Heading size={5}>Players</Heading>;
  }

  render() {
    const {players, tournamentId, setTournament, canRemove} = this.props;

    if (!players || !players.length) {
      return (
        <article>
          {this.renderTitle()}
          <p>No players registered yet.</p>
        </article>
      );
    }

    return (
      <article className="PlayerCardList">
        {this.renderTitle()}
        {players.map((player) => (
          <PlayerCard
            key={player.id}
            player={player}
            tournamentId={tournamentId}
            setTournament={setTournament}
            canRemove={!!canRemove}
          />
        ))}
      </article>
    );
  }
}
